
import React from 'react';
import AsideLayout from '../components/AsideLayout';
import { graphql } from 'gatsby'
import { blockImg } from '../styles/util';
import Article from '../components/Article';
import Img from 'gatsby-image';
import parser from 'html-react-parser';

function CsaPage({data}) {
  const {html, frontmatter: {title, description}} = data.file.childMarkdownRemark;

  return (
    <AsideLayout title={title} description={description}>
      {parser(html)}
    </AsideLayout>
  );
}

export default CsaPage;


export const query = graphql`
  query($filePath: String!) {
    file(relativePath: {eq: $filePath}) {
      childMarkdownRemark {
        frontmatter {
          title
          description
        }
        html
      }
    }
  }
`;